
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































































































































































































































@import '~vue-file-agent/dist/vue-file-agent.css';

.contact-form {
  ::v-deep {
    input.vs__search {
      padding: 1rem 0;

      &::placeholder {
        color: $c-black-60;
        font-size: 1.5rem;
      }
    }
  }
}

.contact-form > div {
  @include mq(m) {
    max-width: col(10, 12);
  }
}

.form__header {
  @include fluid(
    margin-bottom,
    (
      s: 72px,
      xl: 64px,
    )
  );
}

.form__header__headline {
  @extend %tag;

  display: inline-flex;
  margin-bottom: 2rem;
}

.form__header__title + .form__header__text {
  @include fluid(
    margin-top,
    (
      s: 12px,
      xl: 16px,
    )
  );
}

.form__header__text {
  color: $c-black-80;
}

.form__input {
  margin-bottom: 4rem;
}

.form__btn {
  margin-bottom: 2rem;
}

.form__send__loading {
  border: 8px solid $c-black-40;
  border-radius: 50%;
  border-top: 8px solid $c-dark-blue;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.contact-form__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  line-height: 3rem;
  letter-spacing: 0.5px;
  text-align: center;
}

.contact-form__success__icon {
  margin-bottom: 2rem;
  display: flex;
  width: 5rem;
  height: 5rem;
  margin-right: 1.5rem;
  background-color: $c-black-20;
  border-radius: 9999em;

  svg {
    margin: auto;
    width: 2.2rem;
    height: 2.2rem;
    fill: $c-green;
  }
}

.contact-form__success__title {
  margin-bottom: 1.2rem;
  text-align: center;
}

.form__validation {
  margin-bottom: 3rem;
}

.form__recaptcha {
  margin-bottom: 3rem;
}

// Upload
.form-label {
  @extend %fw-normal;

  color: $c-black;
  font-size: 1rem;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.4rem;
  }
}

.form__upload {
  margin-top: 1rem;
}

.form-files__info {
  color: $c-black-50;
  font-size: 1.3rem;
  text-align: right;
}

// Keyframes
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
