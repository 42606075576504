
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































































@import 'vue-select/src/scss/vue-select.scss';

.form-select {
  position: relative;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    .icon {
      transform: rotate(45deg);
    }
  }

  &.is-open {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.select-control {
  display: none;
}

.select__input {
  margin-top: 1rem;
}

.form-select__label {
  @extend %fw-normal;

  color: $c-black;
  font-size: 1rem;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.4rem;
  }
}

.real-select {
  @extend %visually-hidden;

  display: none;
}

.form-options {
  @extend %shadow;

  position: absolute;
  z-index: 1;
  top: 41px;
  overflow: hidden;
  width: 100%;
  max-height: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
  background: $c-white;
  transition: 0.3s;

  .is-open &,
  &:not(.is-open):focus-within {
    max-height: 500px;
    padding: 15px 0;
  }
}

.form-option {
  display: block;
  width: 100%;
  padding: 0 15px;
  line-height: 30px;
  transition: 0.1s background, opacity 0s;

  &.placeholder {
    color: rgba($c-black, 0.5);
  }

  &:hover,
  &:focus {
    background: $c-black;
  }
}

.form-select__error {
  margin-top: $spacing / 4;
}

// Overwrite plugin v-select

.vs__dropdown-toggle {
  border: 0;
  border-bottom: 1px solid $c-black-40;
  border-radius: 0;
}

.vs__selected-options {
  padding: 0;

  @include mq($until: l) {
    font-size: 1.6rem;
  }
}

.vs__selected {
  margin-inline: 0;
  padding: 0;
}
