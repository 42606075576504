
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































.form-group {
  position: relative;
  width: 100%;

  @include mq('s') {
    grid-column: 1 / 3;
  }
}

.form-label {
  @extend %fw-normal;

  color: $c-black;
  font-size: 1rem;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.4rem;
  }
}

.form-textarea {
  box-sizing: border-box;
  width: 100%;
  height: 20rem;
  margin-top: 1rem;
  padding: 2.5rem 3rem;
  border: 1px solid $c-black-40;
  border-radius: 16px;
}
