
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































































.form-group {
  position: relative;

  .full {
    width: 100%;
  }
}

.form-input {
  box-sizing: border-box;
  width: 100%;
  padding: $spacing * 0.5 0;
  font-weight: 300;
  border: 0;
  border-bottom: 1px solid $c-black-40;
  -moz-appearance: textfield; // stylelint-disable property-no-vendor-prefix

  &::placeholder {
    color: $c-black-60;
    font-size: 1.5rem;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-internal-autofill-selected {
    background-color: $c-white !important; // stylelint-disable declaration-no-important
    -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    box-shadow: 0 0 0 1000px $c-white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.form-label {
  @extend %fw-normal;

  color: $c-black;
  font-size: 1rem;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.4rem;
  }
}
